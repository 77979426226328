/* eslint-disable import/no-webpack-loader-syntax */
import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form'; // Import React Hook Form
import MarkdownWorker from 'worker-loader!./markdown.worker.ts'; // Web Worker import
import DOMPurify from 'dompurify'; // Sanitize in the main thread

interface WorkerResponse {
  formatted: string;
}

function App() {
  const [formattedContent, setFormattedContent] = useState<string>(''); // Formatted content from worker
  const [isPreviewVisible, setIsPreviewVisible] = useState<boolean>(false); // State to show/hide preview

  // React Hook Form setup
  const { register, handleSubmit, formState: { isDirty }, watch } = useForm({
    defaultValues: { content: '' }
  });

  const content = watch("content"); // Watch for content changes in the textarea

  // Handle the preview button click
  const handlePreview = () => {
    const worker = new MarkdownWorker();
    worker.postMessage({ content });

    worker.onmessage = (e: MessageEvent<WorkerResponse>) => {
      const { formatted } = e.data;
      const sanitizedContent = DOMPurify.sanitize(formatted);
      setFormattedContent(sanitizedContent);
      setIsPreviewVisible(true); // Set preview visible after processing
      worker.terminate(); // Terminate after completion
    };
  };

  // Handle copy to clipboard
  const handleCopy = async () => {
    if (formattedContent) {
      const blob = new Blob([formattedContent], { type: 'text/html' });
      const data = [new ClipboardItem({ 'text/html': blob })];
      await navigator.clipboard.write(data);
      alert('Formatted content copied to clipboard!');
    }
  };

  // When text is edited, reset the preview visibility
  useEffect(() => {
    if (isDirty) {
      setIsPreviewVisible(false); // Reset preview visibility when content is edited
    }
  }, [isDirty]);

  return (
    <div className="container mx-auto h-screen p-4 flex flex-col">
      {/* Title */}
      <h1 className="text-2xl font-bold mb-2 text-center">TidyText.cc</h1>

      {/* Flex container for the three columns */}
      <div className="flex flex-col md:flex-row flex-grow space-y-4 md:space-y-0 md:space-x-4">
        {/* Left Column: Markdown Input Area */}
        <div className="flex flex-col w-full md:w-2/5 h-full">
          <textarea
            {...register("content", { required: true })} // Register textarea with React Hook Form
            className="border p-2 flex-grow overflow-auto max-h-[calc(100vh-160px)] md:max-h-[calc(100vh-160px)]" // Constrain height
            placeholder="Paste your ChatGPT output here..."
          />
        </div>

        {/* Middle Column: Buttons and Controls */}
        <div className="flex flex-col space-y-4 items-center justify-center w-full md:w-1/5">
          {/* TidyText Preview Button */}
          <button
            className={`${isDirty
              ? "bg-indigo-400 text-white shadow-[0_6px_#6366f1] hover:shadow-[0_4px_#6366f1] hover:top-1 active:shadow-none active:top-3"
              : "bg-zinc-300 text-zinc-500 shadow-[0_6px_#a1a1aa] cursor-not-allowed"
              } font-bold rounded-full px-6 py-4 transition-all duration-100 relative`}
            onClick={handleSubmit(handlePreview)} // Use handleSubmit to validate before preview
            disabled={!isDirty} // Disable until content is dirty
          >
            TidyText Preview
          </button>

          <button
            className={`${isPreviewVisible
              ? "bg-indigo-400 text-white shadow-[0_6px_#6366f1] hover:shadow-[0_4px_#6366f1] hover:top-1 active:shadow-none active:top-3"
              : "bg-zinc-300 text-zinc-500 shadow-[0_6px_#a1a1aa] cursor-not-allowed"
              } font-bold rounded-full px-6 py-4 transition-all duration-300 relative`}
            onClick={handleCopy}
            disabled={!isPreviewVisible} // Disable until preview is visible
          >
            Copy
          </button>
        </div>

        {/* Right Column: Preview Area */}
        <div className="relative flex flex-col w-full md:w-2/5 h-full border p-4 overflow-auto max-h-[calc(100vh-160px)] md:max-h-[calc(100vh-160px)]">
          {/* Conditionally render overlay */}
          {!isPreviewVisible && (
            <div className="absolute inset-0 bg-white bg-opacity-75 flex items-center justify-center">
              {isDirty ? "Press TidyText Preview to continue" : "Enter text to continue"}
            </div>
          )}

          {isPreviewVisible && (
            <div className="flex-grow overflow-auto" dangerouslySetInnerHTML={{ __html: formattedContent }} />
          )}
        </div>
      </div>
    </div>
  );
}

export default App;